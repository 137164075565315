import { Box, Button, Grid, Portal } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import ReactToPdf from 'react-to-pdf';
import { Page } from '../../components/Page';
import { Milestone } from '../../models/Milestone';
import { Project } from '../../models/Project';
import { useMilestones } from '../../queries/useMilestones';
import { DateAmountSlider } from './components/DateAmountSlider';
import { GantChart } from './components/GantChart';
import { RoadmapHeader } from './components/RoadmapHeader';
import { RoadmapSidebar } from './components/RoadmapSidebar';
import { GantChartContext } from './contexts/GantChartContext';
import { GRID_SIDEBAR_WIDTH, useGantChart } from './hooks/useGantChart';

interface RoadmapProps {
	projects: Project[];
	selectedProject?: Project;
}

export const Roadmap = ({ projects, selectedProject }: RoadmapProps): JSX.Element => {
	console.log('Roadmap', { projects, selectedProject });
	const ref = useRef<HTMLDivElement | null>(null);

	console.log({ projects, selectedProject });

	const [state, dispatch] = useGantChart({
		initialState: {
			type: 'Days',
			date: new Date(),
			dateOffset: 0,
			project: selectedProject ?? projects[0],
			tasks: [],
			milestones: []
		}
	});
	const { data } = useMilestones(
		state.project?.projectId ?? selectedProject?.projectId ?? projects[0].projectId
	);
	console.log({ data });

	useEffect(() => {
		// Map to Milestone class
		dispatch({
			type: 'SetMilestones',
			payload:
				data?.milestones.map((m) => {
					return new Milestone(
						m.milestoneId,
						m.title,
						m.description,
						m.estimatedHours,
						m.startDate,
						m.endDate,
						m.projectId,
						m.projectTasks
					);
				}) ?? []
		});
	}, [data, dispatch]);

	// Should I put the "setCalendarType" in a callback or useEffect?

	return (
		<Page
			title={'Gantt chart'}
			backgroundColor={'#fff'}
			actions={
				state.project && (
					<ReactToPdf
						targetRef={ref}
						filename={`Gigover-gantt-${state.project?.projectId}.pdf`}
						options={
							ref.current && {
								orientation: 'landscape',
								unit: 'px',
								hotfixes: ['px_scaling'],
								format: [
									ref.current?.clientWidth ?? 1920,
									ref.current?.clientHeight ?? 1080
								]
							}
						}
					>
						{({ toPdf }) => <Button onClick={toPdf}>Download as PDF</Button>}
					</ReactToPdf>
				)
			}
		>
			<>
				<GantChartContext.Provider value={[state, dispatch]}>
					<div ref={ref} id={'gc-container'}>
						<RoadmapHeader />
						<Box mt={8}>
							<Grid
								templateColumns={`${GRID_SIDEBAR_WIDTH} 1fr`}
								templateRows={'auto 1fr'}
								rowGap={4}
								columnGap={8}
							>
								<RoadmapSidebar />
								<GantChart />
							</Grid>
						</Box>
					</div>
					<Portal>
						<DateAmountSlider />
					</Portal>
				</GantChartContext.Provider>
			</>
		</Page>
	);
};
